@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}


body {
    min-height: 150vh;
    background: #1B252E;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
}

a, .link {
    text-decoration: none;
    color: #FFF;
    cursor: pointer;
}

button, .btn,
input, .input, textarea, .textarea {
    display: inline-block;
    border: none;
    outline: none;
}

button, .btn, a, .link {
    cursor: pointer;
}

.container {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 1rem;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.page-header {
    padding-top: 100px;
}


/* not found  */
.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 80vw;
    padding-top: 150px;
    margin: 0 auto 5rem;
}

.notFound h2 {
    color: #FFF;
}

.imgNotFound {
    width: 50%;
    margin-bottom: 1em;
    margin-bottom: 2rem;
}

.backToHome {
    color: #fff;
    background-color: #F5A524;
    border: 1px solid #F5A524;
    padding: 0.7em 1.5em;
    text-decoration: none;
    border-radius: 8px;
    margin-top: 2rem;
}

.results-sec {
    padding: 6rem 0 4rem;
    background-size: cover;
}

.results-sec .section-title .title {
    text-transform: capitalize;
}